import api from '@/services/api/api';

export const useFilms = (
  params = {
    page: 1,
    filters: {},
  },
) => {
  const films = useInfiniteQuery(
    ['films', params.filters, params.page],
    async ({ pageParam = toValue(params.page) }) => {
      const { data } = await api.lectures.getFilms(
        {
          per_page: params.per_page,
          page: pageParam,
        },
        toValue(params.filters),
      );

      return data;
    },
    {
      staleTime: Infinity,
      select: ({ pages }) => ({
        meta: pages[pages.length - 1].meta,
        data: pages.flatMap((data) => data.data),
      }),
      getNextPageParam: (data) => {
        return data.meta.current_page < data.meta.last_page
          ? data.meta.current_page + 1
          : undefined;
      },
      refetchOnMount: true,
      keepPreviousData: true,
    },
  );

  const queryClient = useQueryClient();

  const resetPage = (filters) => {
    queryClient.setQueryData(['films', filters], (prevData) => {
      if (!prevData) return;
      return {
        pages: [prevData.pages[0]],
        pageParams: [prevData.pageParams[0]],
      };
    });
  };

  onServerPrefetch(async () => {
    await films.suspense();
  });

  return {
    films,
    resetPage,
  };
};
